// @ts-strict-ignore
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import type { ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./DeprecatedFormFieldInformation.styles";

interface Props {
  /**
   * ID of the parent field, used to associate aria attributes to HTML input
   */
  id?: string;
  /**
   * HTML Default disabled attribute
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * An array of strings containing possible field-level errors
   */
  errors?: ReactNode[];
  /**
   * ReactNode used to render the descriptive text underneath the field
   */
  descriptiveText?: ReactNode;
}

const DeprecatedFormFieldInformation = ({
  disabled,
  descriptiveText,
  errors,
  id,
  ...rest
}: Props) => {
  const hasErrors = () => {
    if (!errors) return false;

    return errors.filter(e => !!e).length > 0;
  };

  const hasDescriptiveText = () => !!descriptiveText;

  const conditionalInformationStyles = () => {
    return [
      styles.informationArea.base,
      hasErrors() && styles.informationArea.errorStyles,
      disabled && styles.informationArea.disabled,
    ];
  };

  const renderErrorMessages = () => {
    const renderMessages = () => {
      return errors.slice(0, 2).map((error, index) => (
        <p
          key={`error-item-${index}`}
          css={styles.errorText}
          data-testid={`error-message-${index}`}
        >
          {error}
        </p>
      ));
    };

    return (
      <div css={styles.errorMessageWrapper}>
        <div>
          <FontAwesomeIcon icon={faExclamationCircle} css={styles.errorIcon} />
        </div>
        <div>{renderMessages()}</div>
      </div>
    );
  };

  if (!hasErrors() && !hasDescriptiveText()) {
    return null;
  }

  return (
    <div data-testid="info-area" css={conditionalInformationStyles()} {...rest}>
      {hasErrors() && (
        <div id={`${id}Error`} aria-live="assertive">
          {renderErrorMessages()}
        </div>
      )}
      {hasDescriptiveText() && !hasErrors() && <>{descriptiveText}</>}
    </div>
  );
};

export { DeprecatedFormFieldInformation };
