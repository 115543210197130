// @ts-strict-ignore
import { useTheme } from "@emotion/react";
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faCheckCircle,
  faInfoCircle,
  faExclamationCircle,
  faBell,
} from "@fortawesome/pro-regular-svg-icons";
import { colorProfileMapper } from "aviary-tokens";
import type { HTMLProps, ReactNode } from "react";

import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { Box, type BoxColors } from "../Box";
import { Typography } from "../Typography";

import * as styles from "./Message.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Content that will be rendered inline with the icon, placed
   * above the content children
   */
  titleContent?: ReactNode;
  /**
   * Flag to determine if the Message has been dismissed
   *
   * @default false
   */
  isDismissed?: boolean;
  /**
   * Add a custom FontAwesome icon to override set icons in all colour profiles except for primary, danger, and warning
   */
  customIcon?: IconDefinition;
  /**
   * Set the intention of the Message
   *
   * @default "system"
   */
  isColor?: BoxColors;
  /**
   * Message content
   *
   * @default undefined
   */
  children?: ReactNode;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/display/Message
 */
const Message = ({
  titleContent,
  isColor = "system",
  isDismissed,
  customIcon,
  children,
  ...rest
}: Props) => {
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  const boxStyles = [styles.box, isColor === "system" && styles.systemColor];

  const iconStyles = [
    styles.icon,
    styles.themedColorIcon(themeColors[isColor]),
    isColor === "system" && styles.systemIcon,
  ];

  if (isDismissed) {
    return null;
  }

  const renderIcon = () => {
    switch (isColor) {
      case "success":
        return faCheckCircle;
      case "system":
        return faInfoCircle;
      case "warning":
      case "danger":
        return faExclamationCircle;
      case "highlight":
      case "info":
        return faBell;
    }
  };

  return (
    <Box isColor={isColor} isBordered padding="xsmall" layoutStyles={boxStyles} {...rest}>
      <div css={styles.contentWrapper}>
        <div>
          <FontAwesomeIcon icon={customIcon ? customIcon : renderIcon()} css={iconStyles} />
        </div>
        <div css={styles.content}>
          {!!titleContent && (
            <Typography isSecondaryWeight css={styles.title}>
              {titleContent}
            </Typography>
          )}
          {children}
        </div>
      </div>
    </Box>
  );
};

export { Message };
